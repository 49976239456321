import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Dawson({ styles, editing, ...props }) {
  const Elements = useComponents()

  const checkboxes_color = styles.forElement("form_content_text").color

  const galleryDefaults = {
    settings: {
      layout: "grid",
      crop: "square",
      radius: "50%",
      columns: 2,
      captionSize: "15",
      captionColor: "#aaaaaa",
      captionWeight: "normal"
    },
    children: [
      {
        type: "Image",
        src:
          "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/asGin6graVnCSVNq9wGrQ3",
        alt: "Donut"
      },
      {
        type: "Image",
        src:
          "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/dGDeUVhqcsqwSF2RnsMCk9",
        alt: "Donut"
      },
      {
        type: "Image",
        src:
          "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/ayY6wRJpdDtFfF59QEfpKs",
        alt: "Donut"
      },
      {
        type: "Image",
        src:
          "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/8hsEe6TX2CPhvFv7Zg5sCp",
        alt: "Donut"
      }
    ]
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <svg
        className="formkit-pattern"
        preserveAspectRatio="none"
        width="705"
        height="1218"
        viewBox="0 0 705 1218"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-34 -17L631.629 -17C631.629 -17 792.195 276.641 598.648 612.945C405.102 949.25 631.629 1239.23 631.629 1239.23H-34V-17Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="-356.841"
            y1="505.716"
            x2="638.225"
            y2="1199.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              style={{
                stopColor: styles.forElement("gradient_one").backgroundColor
              }}
            />
            <stop
              offset="1"
              style={{
                stopColor: styles.forElement("gradient_two").backgroundColor
              }}
            />
          </linearGradient>
        </defs>
      </svg>
      <div
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
        className="formkit-bg-color"
      />
      <div
        style={styles.forElement("background")}
        className="formkit-bg-image"
      />
      <div className="formkit-container">
        <div
          className="formkit-form-container"
          role="complementary"
          style={{
            backgroundColor: styles.forElement("form_background")
              .backgroundColor,
            borderRadius: styles.forElement("form_border_radius").borderRadius
          }}
        >
          <div className="formkit-image-container">
            <Elements.Image
              className="formkit-form-image"
              name="image"
              defaults={{
                src:
                  "https://images.unsplash.com/photo-1533910534207-90f31029a78e?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjExODA0N30&utm_source=convertkit&utm_medium=referral&utm_campaign=api-credit?fit=max&w=800"
              }}
              size={{ w: 560, h: 330 }}
            />
          </div>
          <Elements.Content
            className="formkit-image-tag"
            name="image_text"
            defaults={{
              content: "Free Recipe!"
            }}
          />
          <div
            className="formkit-form-content-container"
            style={styles.forElement("form_border_radius")}
          >
            <Elements.Heading
              className="formkit-form-heading"
              name="form_heading"
              tag="h2"
              defaults={{
                content: "Choose a free recipe from my newest cookbook!"
              }}
            />
            <Elements.Region
              className="formkit-form-content"
              name="form_content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "Pop your info in and choose a featured recipe to download it for free!"
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields
                style={{
                  color: checkboxes_color
                }}
              >
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Sign Up and Download" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              className="formkit-disclaimer"
              name="disclaimer"
              defaults={{
                content: "I won’t send you spam. Unsubscribe anytime."
              }}
            />
          </div>
        </div>
        <div
          className={`formkit-content-container ${
            editing ? "formkit-editing" : ""
          }`}
          role="main"
        >
          <header>
            <Elements.Heading
              className="formkit-heading"
              name="heading"
              defaults={{
                content: "Sweet & spice & everything nice."
              }}
            />
          </header>
          <Elements.Region className="formkit-subheading" name="subheading">
            <Elements.Content
              defaults={{
                content: "Choose a free recipe from my newest cookbook!"
              }}
            />
          </Elements.Region>
          <Elements.Region className="formkit-content" name="content">
            <Elements.Content
              defaults={{
                content:
                  "<p>The cookbook is everything it says it is: sweet and spice and everything nice. Delicious, sweet treats with some spicy twists along the way – my favorite way to bake!</p><p>Pop your info in and choose from the featured recipes below to download it for free!</p>"
              }}
            />
            <Elements.Gallery
              defaults={galleryDefaults.settings}
              defaultChildren={galleryDefaults.children}
            />
          </Elements.Region>
        </div>
      </div>
      <Elements.BuiltWith background="background" image="background" />
    </Elements.LandingPage>
  )
}

export default createTemplate(Dawson, { name: "Dawson" })
